import { createApp, reactive } from 'vue'
import App from './App.vue'
import router from './router'
import Store from "./store/store";

import uiPlugin from "@/plugins/ui/uiPlugin";

import './assets/styles/tailwind.css';

const dayjs = require('dayjs')
require('dayjs/locale/de')
dayjs.locale('de')

const app = createApp(App)

app.config.globalProperties.foo = 'bar'
app.config.globalProperties.$store = Store

app.config.globalProperties.$env = reactive({
    sidebarIsOpen: false,
    whiteLabel: {
        mailProduct: 'E-Mail Marketing',
        product: 'Veranstaltungen',
        mailDomain: 'https://live.sendnode.com/',
        loginDomain: 'https://events.sendnode.com/',
        publicDomain: 'https://events.sendnode.com/'
    },
    searchField: ''
})

app.config.globalProperties.$dayjs = dayjs

app.use(uiPlugin, {
    prefix: 'ui'
})

app.use(router)
app.mount('#app')
