<template>
 <div class="c-header">
   <HeaderColumn v-for="(column, index) in columns" :key="index" :width="column.width" :data="column.label" />
   <HeaderColumn :width="actions*2" data="" v-if="actions > 0" />
 </div>
</template>

<script>
import HeaderColumn from "./HeaderColumn";
export default {
  name: "Header",
  props: {
    columns: Array,
    actions: Number
  },
  components: {
    HeaderColumn
  }
}
</script>

<style scoped>
.c-header{
  @apply border-b-2 w-full px-2 pt-4 pb-2 flex
}
</style>
