<template>
 <div class="c-headercol" :class="{'c-headercol-custom-width': width > 0}" :style="{width: width + 'rem'}">
   {{ data }}
 </div>
</template>

<script>
export default {
name: "HeaderColumn",
  props: ['data', 'width']
}
</script>

<style scoped>
.c-headercol{
  @apply flex-grow  flex-1 px-2 font-bold
}
.c-headercol-custom-width{
  @apply flex-none !important
}
</style>
