<template>
  <router-view />
</template>

<script>
import {getPACCOUNT} from "@/store/auth";
import {reseller} from "@/whitelabel";

export default {
  data(){
    return {

    }
  },
  mounted() {

    this.$env.whiteLabel = Object.assign({}, reseller(getPACCOUNT()))
  }
}
</script>

<style lang="scss">

 @import "./assets/styles/tailwind.css";

  @import './scss/bootstrap.scss';



</style>
<style>
.btn{
  @apply px-4 py-2 bg-gray-600 rounded-md text-white font-medium text-sm tracking-wide text-center
}
@screen sm {
  .btn{
    @apply w-full
  }
}
.btn:hover{
  @apply bg-gray-500
}

.c-tab-bar {
  @apply overflow-x-scroll rounded-r
}
.c-tab-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.c-tab-bar::-webkit-scrollbar {
  display: none;
}

.c-tab-bar .active{
  @apply bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold cursor-pointer
}

.c-tab-bar .inactive{
  @apply bg-white inline-block py-2 px-4 rounded-t text-blue-500 font-semibold cursor-pointer
}

.c-tab-bar .inactive:hover{
  @apply bg-white text-blue-800
}

.c-tab-bar .disabled{
  @apply bg-white inline-block py-2 px-4 text-gray-400 rounded-t font-semibold pointer-events-none cursor-default
}

.c-tab-bar>ul{
  @apply flex
}

.c-tab-bar>ul>li{
  @apply mr-1
}

.c-view-h1{
  @apply text-gray-700 text-3xl font-medium mb-4
}

.c-view-h2{
  @apply text-gray-700 text-xl font-medium
}

</style>
