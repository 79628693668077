<template>
  <label class="block c-field-content relative">
    <input class="form-input mt-1 block w-full" v-model="value" :placeholder="placeholder">
    <div class="c-calendar w-full max-w-md" @mousedown="(e)=>e.preventDefault()">
      <div class="c-settings flex mb-4">
        <div class="c-select-month flex-1 text-center">
          <i class="fa fa-angle-left mr-2 cursor-pointer" @click="(month - 1) === 0 ? month = 12 : (month--)"></i>
          <span class="inline-block w-24">{{months[month-1]}}</span>
          <i class="fa fa-angle-right ml-2 cursor-pointer" @click="(month + 1) === 13 ? month = 1 : (month++)"></i>
        </div>
        <div class="c-select-year flex-1 text-center">
          <i class="fa fa-angle-left mr-2 cursor-pointer" v-show="year > thisYear" @click="year--"></i>
          {{year}}
          <i class="fa fa-angle-right ml-2 cursor-pointer" v-show="year < (thisYear+2)" @click="year++"></i>
        </div>
      </div>
      <div class="c-month">
        <div class="c-toprow">
          <div class="c-days" v-for="(day, index) in days" :key="index">
            {{day}}
          </div>
        </div>
        <div class="c-row" v-for="(row, index) in monthView" :key="index">
          <div class="c-day"
               :class="{'invisible': day === 0,'today': isToday(day),'selected': isSelected(day),'selectable': isSelectable(day)}"
               @click="setDate(day)"
               v-for="(day, dindex) in row"
               :key="dindex">
            {{day > 0 ? day : ' '}}
          </div>
        </div>
      </div>
    </div>
  </label>
</template>

<script>
import calendarize from 'calendarize';
import {zeros} from "@/store/store";

const today = new Date()

export default {
name: "Calendar",
  data(){
   return {
     days: ['Mo','Di','Mi','Do','Fr','Sa','So'],
     months: ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
     today: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(),
     day: null,
     month: today.getMonth()+1,
     year: today.getFullYear(),
     thisYear: today.getFullYear(),
   }
  },
  props: {
    modelValue: String,
    type: String,
    placeholder: String,
    startDate: String
  },
  methods: {
    isToday(day = 1){
      return this.today === this.year + '-' + this.month + '-' + day
    },
    isSelected(day = 1){
      return this.year + '-' + this.month + '-' + this.day === this.year + '-' + this.month + '-' + day
    },
    isSelectable(day = 1){
      return day !== 0
      // return this.selectable.includes(this.year + '-' + this.month + '-' + day)
    },
    setDate(day){
      if (this.isSelectable(day) === false) return
      this.day = day
      this.value = this.year + '-' + zeros(this.month) + '-' + zeros(day)
      this.$emit('setdate')
    }
  },
  computed: {
    value: {
      get() {
        if (this.$dayjs(this.modelValue).isValid()) return this.$dayjs(this.modelValue).format('dd, DD.MM.YYYY')
        return 'Bitte wählen'
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    monthView(){
      return calendarize(this.year + '-' + this.month + '-01', 1);
    }
  }
}
</script>

<style scoped>
.c-calendar{
  @apply border rounded p-4 hidden absolute right-0 left-0 bg-white mt-1 z-50
}
.form-input:focus ~ .c-calendar{
  @apply block
}
.c-month{
  @apply
}
.c-row{
  @apply flex w-full justify-evenly
}
.c-day{
  @apply text-center text-gray-400 w-8 h-8 rounded-3xl border-4 border-transparent
}
.c-day.selectable{
  @apply text-center cursor-pointer text-black
}
.c-day.today{
  @apply bg-gray-200 border-gray-200
}
.c-day.selected{
  @apply border-info
}
.c-day.invisible{
  @apply cursor-auto
}
.c-toprow{
  @apply flex w-full justify-evenly
}
.c-days{
  @apply text-center w-6
}
</style>