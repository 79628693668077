export const resellers = {
    default: {
        mailProduct: 'E-Mail Marketing',
        product: 'Veranstaltungen',
        mailDomain: 'https://live.sendnode.com/',
        loginDomain: 'https://events.sendnode.com/',
        publicDomain: 'https://events.sendnode.com/'
    },
    '13': {
        mailProduct: 'MAILINGWORK',
        product: 'Event-Tool',
        mailDomain: 'https://login.mailingwork.de/',
        loginDomain: 'https://events.mailingwork.de/',
        publicDomain: 'https://events.mailingwork.de/'
    },
    '7771': {
        mailProduct: 'MAILINGWORK',
        product: 'Event-Tool',
        mailDomain: 'https://login.mailingwork.de/',
        loginDomain: 'https://events.mailingwork.de/',
        publicDomain: 'https://events.mailingwork.de/'
    },
    '26731': {
        mailProduct: 'MAILINGWORK',
        product: 'Event-Tool',
        mailDomain: 'https://login.mailingwork.de/',
        loginDomain: 'https://events.mailingwork.de/',
        publicDomain: 'https://events.mailingwork.de/'
    },
    '4760': {
        mailProduct: 'EMMA',
        product: 'S-Events',
        mailDomain: 'https://emma.sparkasse.de/',
        loginDomain: 'https://events.sparkassen-finanzportal.de/',
        publicDomain: 'https://events.sparkasse.de/'
    }
}

export function reseller(id){
    let r
    if(typeof resellers[String(id)] !== 'undefined') r = resellers[String(id)]
    if(typeof r === 'undefined') r = resellers.default
    return r
}