<template>
  <div class="c-row">
    <Column v-for="(column, index) in columns" :key="index" :property="column" :width="column.width" :data="data[column.prop]" />
    <ActionsColumn :actions="actions" v-if="actions.length > 0" :data="data" />
  </div>
</template>

<script>
import Column from "./Column";
import ActionsColumn from "./ActionsColumn";

export default {
name: "Row",
  components: {
    Column,
    ActionsColumn
  },
  props:{
    data: Object,
    columns: Array,
    actions: Array,
  }
}
</script>

<style scoped>
.c-row{
  @apply border-b flex w-full px-2 py-2 flex
}
</style>
