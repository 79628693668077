<template>
 <div class="c-footer">
   <div class="pagination py-3 text-right" v-if="pages.pagination">
     <Button size="sm" @click="$emit('lowerPage')" v-if="pages.page > 1"><i class="fas fa-caret-left"></i></Button>
     <span class="px-4 font-bold">{{pages.page}} von {{ pages.maxpage }}</span>
     <Button size="sm" @click="$emit('raisePage')" v-if="pages.page < pages.maxpage"><i class="fas fa-caret-right"></i></Button>
   </div>

 </div>
</template>

<script>
import Button from '../Button'

export default {
  name: "Footer",
  components: {
    Button
  },
  props:{
    pages: Object
  }
}
</script>

<style scoped>
.c-footer{
  @apply px-4 py-1
}
</style>
