<template>
  <div class="c-cards">
    <slot></slot>
  </div>
</template>

<script>
export default {
name: "Cards"
}
</script>

<style scoped>
  .c-cards {
    display: grid;

    grid-gap: 12px;
    grid-template-columns: repeat(3, 1fr);
  }
</style>
