<template>
  <div class="e-gauge">

  </div>
</template>

<script>
export default {
name: "Gauge"
}
</script>

<style scoped>

</style>
