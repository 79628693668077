<template>
  <div>
    <h2 class="c-view-h2" v-if="subtitle">{{subtitle}}</h2>
    <h1 class="c-view-h1">{{title}}</h1>
    <div class="bg-white rounded border p-4">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "Page",
  props: {
    subtitle: String,
    title: String
  },
  computed: {

  }
}
</script>

<style scoped>

</style>
