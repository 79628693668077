<template>
  <label class="block c-field-content">
    <textarea v-if="type === 'multiline'" class="form-textarea mt-1 block w-full" rows="3" v-model="value" :placeholder="placeholder" :disabled="disabled"></textarea>
    <input v-else :type="type" class="form-input mt-1 block w-full" v-model="value" :placeholder="placeholder" :readonly="disabled">
  </label>
</template>
<script>
export default {
  name: "Input",
  props: {
    type: String,
    placeholder: String,
    modelValue: String,
    readonly: Boolean
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    disabled(){
      if(this.readonly === true) return true
      return false
    }
  }
}
</script>

<style scoped>

</style>
