<template>
  <div>
    <div class="c-loading" v-if="showLoading">
      <div class="c-loader">
        <div class="pulse"></div>
      </div>
      <div class="c-message">
        Sie werden eingeloggt.
      </div>
    </div>
    <div class="c-login" v-if="local">
      <div class="c-login-overlay">
        <div class="c-contents">
          <ui-field label="Session ID">
            <ui-form-input v-model="sessionId"/>
          </ui-field>
          <ui-field label="Account ID">
            <ui-form-input v-model="accountId"/>
          </ui-field>
          <ui-field label="Parent Account ID">
            <ui-form-dropdown :options="options" v-model="parentAccountId"/>
          </ui-field>
          <ui-field label="">
            <ui-button title="primary" type="primary" @click="authorize()">Autorisieren</ui-button>
          </ui-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {reseller} from "@/whitelabel";

export default {
  name: 'Home',
  data() {
    return {
      sessionId: '',
      accountId: '',
      parentAccountId: '13',
      showLoading: false,
        local: false,
      options: [
      {
        value: '13',
        label: 'Mailingwork (13)'
      },
      {
        value: '4760',
        label: 'SFP (4760)'
      }
    ]

    }
  },
  methods: {
    async authorize() {
      await this.$store.loginSession(this.sessionId, this.accountId, this.parentAccountId)
      if(this.$store.authorized()){
        this.$env.whiteLabel = Object.assign({}, reseller(this.parentAccountId))
        this.$router.push('/admin/events')
      }else{
        this.$uiToast.add("Es ist ein Fehler aufgetreten.")
      }

    },
    getParam(e){
      let url = new URL(window.location.href)
      return url.searchParams.get(e)
    }
  },
  created() {
    this.parentAccountId = this.getParam('parent_acc')
    this.accountId = this.getParam('acc')
    this.sessionId = this.getParam('sid')
  },
  async mounted() {

    document.title = this.$env.whiteLabel.product

    if(window.location.host === 'login.events.sendnode.com'){

      let domain = reseller(this.parentAccountId).loginDomain || this.$env.whiteLabel.loginDomain
      // console.log(domain)
      window.location = domain + '?sid=' + this.sessionId + '&acc=' + this.accountId + '&parent_acc=' + this.parentAccountId

    }else if(window.location.hostname === 'localhost'){
      this.local = true
    }else{
      let prequel = true
      if (typeof this.accountId === 'string' && this.accountId.length > 0) prequel = false
      if (typeof this.sessionId === 'string' && this.sessionId.length > 10) prequel = false
      if (prequel === false) {
        this.showLoading = true
        this.authorize()
      }
    }
  }
}
</script>

<style scoped>
.c-login {
  @apply fixed bg-gray-200 p-0 top-0 bottom-0 left-0 right-0 z-40
}

.c-login-overlay {
  @apply flex justify-center items-center h-screen px-6
}

.c-contents {
  @apply bg-white rounded p-4
}

.c-loading {
  @apply z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center flex-col
}

.c-loader {
  @apply bg-white
}

.pulse {
  @apply w-16 h-16 bg-primary
}

.pulse {
  border-radius: 50%;
  outline: 1px solid transparent;
  animation: pulseanim 1.2s ease-in-out infinite;
}

@keyframes pulseanim {
  0% {
    transform: scale(0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
</style>
