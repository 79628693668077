<template>
  <label class="block c-field-content">
    <select class="form-select inline-block w-24 mt-1 mr-2" v-model="_hour">
      <option :value="undefined" disabled selected hidden> </option>
      <option v-for="h in _Hoptions" :key="h" :value="h">{{h}}</option>
    </select>
    <select class="form-select inline-block w-24 mt-1" v-model="_min">
      <option :value="undefined" disabled selected hidden> </option>
      <option v-for="min in _Moptions" :key="min" :value="min">{{min}}</option>
    </select>
  </label>
</template>
<script>
import {zeros} from "@/store/store";

export default {
  name: "Timepicker",
  props: {
    modelValue: String
  },
  data(){
    return {
      min: undefined,
      hour: undefined
    }
  },
  computed: {
    _Moptions(){
      let ret = []
      for (let i = 0; i < 60; i++) {
        ret.push(zeros(i))
      }
      return ret
    },
    _Hoptions(){
      let ret = []
      for (let i = 0; i < 24; i++) {
        ret.push(zeros(i))
      }
      return ret
    },
    _min: {
      get() {
        return this.min
      },
      set(value) {
        if(this.hour) this.value = this.hour + ':' + zeros(value) + ':00'
        this.min = zeros(value)
      }
    },
    _hour: {
      get() {
        return this.hour
      },
      set(value) {
        if(this.min) this.value = zeros(value) + ':' + this.min + ':00'
        this.hour = zeros(value)
      }
    },
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
