<template>
  <button class="c-button" :disabled="disabled" :class="[size, _type]">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    size: String,
    type: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _type(){
      switch (this.type){
        case    'blank':      return 'border-gray-500 border bg-white'
        case    'error':      return 'bg-error text-white'
        case    'warning':      return 'bg-warning text-white'
        case    'success':      return 'bg-success text-white'
        case    'info':      return 'bg-info text-white'
        case    'secondary':      return 'bg-secondary text-white'
        case    'primary':
        default:                  return 'bg-primary text-white'
      }
    }
  }
}
</script>

<style scoped>
.c-button{
  @apply px-4 py-2 rounded-md font-medium text-sm tracking-wide text-center bg-opacity-100 mt-1
}

.c-button:hover{
  @apply opacity-75
}

.c-button[disabled] {
  opacity: .5;
}

.c-button.sm{
  @apply px-3 py-1
}

.c-button.xs {
  @apply py-2 px-2 my-1;
}
</style>
