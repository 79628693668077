export const location = new Object({
    name: "",
    type: "ROOM",
    description: "",
    addressId: "",
    roomQuota: null
})


export async function getLocation (id) {
    if (this.debug) {
        console.log('getLocation triggered with', id)
    }
    return await this.api().getRequest('/locations/'+id, false, (e) => {
        this.state.location = Object.assign({}, e)
    })
}

export async function deleteLocation (id) {
    if (this.debug) {
        console.log('deleteLocation triggered with', id)
    }
    return await this.api().deleteRequest('/locations/'+id, () => {
        clearLocation()
    })
}

export async function createLocation (location) {
    if (this.debug) {
        console.log('createLocation triggered with', location)
    }

    let res
    await this.api().postRequest('/locations', location, (resc) => {
        res = resc
        this.getLocations()
    })
    return res.id
}

export async function updateLocation (location = {}) {
    if (this.debug) {
        console.log('updateLocation triggered with', name)
    }
    return await this.api().putRequest('/locations/'+this.state.location.id, location, () => {
        this.getLocations()
        return true
    })
}

export function clearLocation() {
    if (this.debug) {
        console.log('clearLocations triggered')
    }
    this.state.location = Object.assign({}, location)
}

const locations = []

export async function getLocations () {
    if (this.debug) {
        console.log('getLocations triggered')
    }
    await this.api().getRequest('/locations', false, (e) => {
        this.state.locations = e.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    })
}

export function clearLocations() {
    if (this.debug) {
        console.log('clearLocations triggered')
    }
    this.state.locations = locations
}

