<template>
  <div class="c-table">
    <Header :columns="columns" :actions="actions.length" />
    <div class="c-rows">
      <Row v-for="(row, index) in paginatedData" :key="index" :data="row" :columns="columns" :actions="actions" />
    </div>
    <div class="c-empty" v-if="data.length === 0">
      <div class="icon text-center py-10 text-gray-500 ">
        <i class="fal fa-times-circle text-6xl"></i>
        <p class="pt-4 font-light">Keine Daten</p>
      </div>
    </div>
    <Footer :pages="pages" @lowerPage="page--" @raisePage="page++" />
  </div>
</template>

<script>
import Header from "./Table/Header";
import Row from "./Table/Row";
import Footer from "./Table/Footer";

export default {
  name: "Table",
  components: {
    Header,
    Row,
    Footer
  },
  data(){
    return {
      page: 1
    }
  },
  props:{
    data: Array,
    columns: Array,
    actions: Array,
    pageSize: Number
  },
  computed:{
    filteredData(){
      const data = this.data
      return data
    },
    paginatedData(){
      if(this.pageSize < 1) return this.filteredData
      const firstItem = (this.page-1) * this.pageSize
      const lastItem = ((this.page-1) * this.pageSize) + this.pageSize
      return this.filteredData.slice(firstItem,lastItem)
    },
    pages(){
      const maxpage = Math.ceil(this.filteredData.length / (this.pageSize||1))
      return{
        page: this.page,
        maxpage: maxpage,
        pagination: this.pageSize > 0 && maxpage > 0
      }
    }
  }
}
</script>

<style scoped>
.c-table{
  @apply w-full bg-white rounded border
}
</style>
