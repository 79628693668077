<template>
 <div class="c-column" :class="{'c-column-custom-width': width > 0}" :style="{width: width + 'rem'}">
   <span :title="data">{{ data }}</span>
 </div>
</template>

<script>
export default {
name: "Column",
  props: ['data','width']
}
</script>

<style scoped>
.c-column{
  @apply flex-grow  flex-1 px-2 overflow-hidden
}
.c-column-custom-width{
  @apply flex-none !important
}
</style>
