const subscriberFields = {}

export async function getSubscriberFields () {
    if (this.debug) {
        console.log('getSubscriberFields triggered')
    }

    await this.api().getRequest('/subscriberfields', false, (e) => {
        this.state.subscriberFields = e
    })
}

export function clearSubscriberFields() {
    if (this.debug) {
        console.log('clearSubscriberFields triggered')
    }
    this.state.subscriberFields = subscriberFields
}

