<template>
  <div class="c-buttons flex flex-row justify-end">
    <slot></slot>
  </div>
</template>

<script>
export default {
name: "Buttons"
}
</script>

<style scoped>
 .c-buttons /deep/ .c-button{
   @apply ml-2
 }
 .c-buttons /deep/ .c-button:last-child{
   @apply mr-0
 }
</style>
