const user = {
    name: ''
}

export async function pullUser (id) {
    if (this.debug) {
        console.log('pullUser triggered')
    }
    await this.api().request(id)
    this.state.user = user
}

export function clearUser() {
    if (this.debug) {
        console.log('clearUser triggered')
    }
    this.state.user = user
}

