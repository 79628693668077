const recipients = []

export async function getRecipients () {
    if (this.debug) {
        console.log('getRecipients triggered')
    }

    const parsedListId = parseInt(this.state.event.mwSubscriberListId, 10);

    if (isNaN(parsedListId) || parsedListId < 1){
        this.state.recipients = recipients
        return
    }

    await this.api().getRequest('/recipients/' + parsedListId, false, (e) => {
        this.state.recipients = e
    })
}

export function clearRecipients() {
    if (this.debug) {
        console.log('clearRecipients triggered')
    }
    this.state.recipients = recipients
}

