
export function api() {
    const api = {}
    // const url = 'http://localhost:4010'
    const url = 'https://api-test.events.sendnode.com/v1'

    api.authorize = async (sid = '', account = '') => {
        console.log(sid, account)
    }
    const request = async  (
        method = 'GET',
        path = null,
        data = false,
        callback = data => data,
        error = data => data,
        authorized = true
    ) => {
        const init = {
            method: method,
            mode: 'cors',
            cache: 'no-cache'
        }
        const headers = {
            'Content-Type': 'application/json'
        }

        if (authorized){
            headers['Authorization'] = 'Bearer '+ this.getJWT()
        }

        init.headers = new Headers(headers)

        if (data !== false) init.body = JSON.stringify(data)

        return fetch(url + path, init)
            .then(res => {
                if (res.ok) {
                    return res
                }
                throw new Error(res.status);
            })
            .then(res => {
                if (res.status === 204) {
                    return Promise.resolve('');
                }

                return res.json()
            })
            .then(callback)
            .catch((e) => {
                error(e)
                if(this.debug === true) {
                    // if(typeof this.uiToast !== 'undefined') this.uiToast.add("API Error: "+ e.message)
                    console.log('There has been a problem with your fetch operation: ', e.message)
                }
            })

    }

    api.request = request
    api.getRequest = (path = null, data = {}, callback = data => data) => {
        return request(
            'GET',
            path,
            data,
            callback,
            e => e,
            true
        )
    }
    api.postRequest = async (path = null, data = {}, callback = data => data, authorized = true) => {
        return await request(
            'POST',
            path,
            data,
            callback,
            e => e,
            authorized
        )
    }
    api.putRequest = async (path = null, data = {}, callback = data => data) => {
        return await request(
            'PUT',
            path,
            data,
            callback,
            e => e,
            true
        )
    }
    api.deleteRequest = async (path = null, data = {}, callback = data => data) => {
        return await request(
            'DELETE',
            path,
            data,
            callback,
            e => e,
            true
        )
    }
    api.sessionLogin = async (accountId, sessionId, successAction) => {
        return await request(
            'POST',
            '/authorization/createTokenByMwSession',
            {
                sessionId: String(sessionId),
                accountId: Number(accountId)
            },
            res => {
                successAction(res)
            },
            e => e,
            false
        )
    }
    return api
}
