const mailings = []

export async function getMailings () {
    if (this.debug) {
        console.log('getMailings triggered')
    }
    await this.api().getRequest('/mailings', false, (e) => {
        this.state.mailings = e
    })
}

export function clearMailings() {
    if (this.debug) {
        console.log('clearMailings triggered')
    }
    this.state.mailings = mailings
}

