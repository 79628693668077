<template>
 <div class="c-column c-column-custom-width" :style="{width: (actions.length*2) + 'rem'}">
   <a v-for="(action, index) in actions" :key="index" class="cursor-pointer" @click="action.action(data)">
     <i :class="'fal '+action.icon"></i>
   </a>
 </div>
</template>

<script>
export default {
name: "ActionsColumn",
  props: ['actions','width', 'data']
}
</script>

<style scoped>
.c-column{
  @apply flex-grow  flex-1 px-2 overflow-hidden
}
.c-column-custom-width{
  @apply flex-none !important
}
</style>
