// eslint-disable-next-line no-unused-vars
function uuid(){
    function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)}
    return b()
}
let duration = 5000

// eslint-disable-next-line no-unused-vars
export function initToast(options = {}){
    const toastContainer = {}
    toastContainer.element = document.createElement("div");
    toastContainer.element.id = toastContainer.id
    toastContainer.element.classList.add('fixed', 'right-0','px-4', 'top-0', 'z-50', 'flex', 'flex-col', 'h-0', 'overflow-visible', 'w-full', 'max-w-md')
    document.body.appendChild(toastContainer.element);
    const toast = {}
    toast.add = (text, type = 'info') => {
        const newToast = {}
        newToast.element = document.createElement("div");
        newToast.element.id = toastContainer.id
        newToast.element.classList.add('bg-white', 'toast-'+type, 'float-right', 'mt-4', 'w-full', 'rounded', 'border', 'shadow-xl')

        toastContainer.element.insertBefore(newToast.element, toastContainer.element.firstChild);


        const toastContents = document.createElement("div");
        toastContents.classList.add('px-4', 'py-1')
        toastContents.innerHTML = text
        newToast.element.appendChild(toastContents);

        const progressBar = document.createElement("div");
        progressBar.classList.add('h-px', 'bg-primary', 'rounded', 'transform', 'transition-transform', 'ease-linear')
        progressBar.style['transition-duration'] = (duration - 350)+'ms'
        newToast.element.appendChild(progressBar);
        progressBar.style['transform'] = 'scaleX(1.0)'


        setTimeout(function(){
            progressBar.style['transform'] = 'scaleX(0)'
        }, 100);
        setTimeout(function(){
            toastContainer.element.removeChild(newToast.element);
        }, duration);
    }
    return toast
}
