import {getSID} from "@/store/auth";

const assets = []

export async function getAssets () {
    if (this.debug) {
        console.log('getAssets triggered')
    }
    // return fetch('https://live.sendnode.com/file/File/ajaxScanImages/false/name/0/100/json?sid='+getSID(), {
    return fetch('https://test.events.sendnode.com/helper/assets.php?sid='+getSID(), {
    })
        .then(response => response.json())
        .then(res => this.state.assets = res.images)
}

export function clearAssets() {
    if (this.debug) {
        console.log('clearAssets triggered')
    }
    this.state.assets = assets
}

