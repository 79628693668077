<template>
  <div class="c-toolbar flex" v-if="isVisible">
    <slot></slot>
    <div class="actions flex-grow text-right">
      <div class="flex justify-end">
        <div v-for="(act, index) in actions" :key="index" class="action pl-2">
          <template v-if="act.isVisible === undefined || act.isVisible()">
            <Button v-if="act.component === 'button'" :title="act.title" :type="act.type" @click="act.action" v-html="act.label"></Button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from './Button'

export default {
  name: "Toolbar",
  components: {
    Button
  },
  props: {
    actions: Array
  },
  computed: {
    isVisible() {
      return this.$slots.default || this.actions.length > 0
    }
  }
}
</script>

<style scoped>
.c-toolbar{
  @apply py-2 mb-2
}
</style>
