import {reactive} from 'vue'
import {api} from "./api";
import {initAuth, loginSession, authorized, logout, refreshToken, getSID, getJWT} from "@/store/auth";
import {pullUser, clearUser} from "./controllers/user";
import {getEvent, clearEvent, getEvents, clearEvents, createEvent, deleteEvent, updateEvent} from "@/store/controllers/event";
import {getRecipients, clearRecipients} from "@/store/controllers/recipients";
import {getTicket, clearTicket, getTickets, clearTickets, createTicket, deleteTicket, updateTicket} from "@/store/controllers/tickets";
import {getSession, clearSession, getSessions, clearSessions, createSession, deleteSession, updateSession} from "@/store/controllers/sessions";
import {getLocation, clearLocation, clearLocations, createLocation, deleteLocation, getLocations, updateLocation} from "@/store/controllers/location";
import {getMailings, clearMailings} from "@/store/controllers/mailings";
import {getAddress, deleteAddress, clearAddress, clearAddresses, createAddress, getAddresses, updateAddress} from "@/store/controllers/address";
import {getTimeslot, clearTimeslot, clearTimeslots, createTimeslot, deleteTimeslot, getTimeslots, updateTimeslot} from "@/store/controllers/timeslots";
import {getLandingpages, clearLandingpages, clearLandingpage, createLandingpage, deleteLandingpage, getLandingpage, updateLandingpage, getPublicLandingpage} from "@/store/controllers/landingpage";
import {getAssets, clearAssets} from "@/store/controllers/assets";
import {getSubscriberFields, clearSubscriberFields} from "@/store/controllers/recipientFields";
import {createOptinSetup} from "@/store/controllers/optinSetup";

export function sluggify(e){e=(e=e.replace(/^\s+|\s+$/g,"")).toLowerCase();for(var a="àáäâèéëêìíïîòóöôùúüûñç·/_,:;",r=0,c=a.length;r<c;r++)e=e.replace(new RegExp(a.charAt(r),"g"),"aaaaeeeeiiiioooouuuunc------".charAt(r));return e=e.replace(/[^a-z0-9 -]/g,"").replace(/\s+/g,"-").replace(/-+/g,"-")}
export function zeros(num, length = 2){return ("00000000000000" + num).slice (-length)}

const store = {
    debug: true,

    api,
    uiToast: undefined,

    state: reactive({}),

    initAuth, loginSession, logout, authorized, refreshToken, getSID, getJWT,

    clearUser, pullUser,

    getEvents, clearEvents,
    getEvent, clearEvent, createEvent, deleteEvent, updateEvent,

    getRecipients, clearRecipients,

    getTickets, clearTickets,
    getTicket, clearTicket, createTicket, deleteTicket, updateTicket,

    getSessions, clearSessions,
    getSession, clearSession, createSession, deleteSession, updateSession,

    getLocations, clearLocations,
    getLocation, clearLocation, createLocation, deleteLocation, updateLocation,

    getMailings, clearMailings,

    getAddresses, clearAddresses,
    getAddress, createAddress, deleteAddress, updateAddress, clearAddress,

    clearTimeslots, getTimeslots,
    getTimeslot, clearTimeslot,  createTimeslot, deleteTimeslot, updateTimeslot,

    getLandingpages, clearLandingpages,
    clearLandingpage, createLandingpage, deleteLandingpage, getLandingpage, updateLandingpage, getPublicLandingpage,

    getAssets, clearAssets,

    getSubscriberFields, clearSubscriberFields,

    createOptinSetup,

    sluggify,zeros

}

store.initAuth()
store.clearUser()
store.clearEvents()
store.clearEvent()
store.clearRecipients()
store.clearTicket()
store.clearTickets()
store.clearSession()
store.clearSessions()
store.clearLocation()
store.clearLocations()
store.clearMailings()
store.clearAddresses()
store.clearAddress()
store.clearTimeslots()
store.clearTimeslot()
store.clearLandingpage()
store.clearLandingpages()
store.clearAssets()
store.clearSubscriberFields()

export default store
