import * as elements from "./Components"
import {initToast} from "./Modules/Toast";

const kebabize = str => {
    return str.split('').map((letter, idx) => {
        return letter.toUpperCase() === letter
            ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
            : letter;
    }).join('');
}

export default {
    install: (app, options) => {

        for (const [name, component] of Object.entries(elements)) {
            app.component(
                String(options.prefix + '-' + kebabize(name)).toLowerCase(),
                component
            )
        }

        app.config.globalProperties['$' + options.prefix + 'Toast'] = initToast(options)

    }
}
