const ticket = new Object({

})


export async function getTicket (id) {
    if (this.debug) {
        console.log('getTicket triggered with', id)
    }
    return await this.api().getRequest('/tickets/'+id, false, (e) => {
        this.state.ticket = Object.assign({}, e)
    })
}

export async function deleteTicket (id) {
    if (this.debug) {
        console.log('deleteTicket triggered with', id)
    }
    return await this.api().deleteRequest('/tickets/'+id, () => {
        clearTicket()
    })
}

export async function createTicket (name = '') {
    if (this.debug) {
        console.log('createTicket triggered with', name)
    }
    let res
    await this.api().postRequest('/tickets', {name: name}, (resc) => {
        res = resc
        this.getTickets()
    })
    return res.id
}

export async function updateTicket (ticket = {}) {
    if (this.debug) {
        console.log('updateTicket triggered with', name)
    }
    return await this.api().putRequest('/tickets/'+this.state.ticket.id, ticket, () => {
        return true
    })
}

export function clearTicket() {
    if (this.debug) {
        console.log('clearTickets triggered')
    }
    this.state.ticket = Object.assign({}, ticket)
}

const tickets = []

export async function getTickets () {
    if (this.debug) {
        console.log('getTickets triggered')
    }
    await this.api().getRequest('/events/'+ this.state.event.id  +'/tickets', false, (e) => {
        this.state.tickets = e
    })
}

export function clearTickets() {
    if (this.debug) {
        console.log('clearTickets triggered')
    }
    this.state.tickets = tickets
}

