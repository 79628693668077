export const address = new Object({
    address: null,
    zip: null,
    city: null,
    country: null
})


export function getAddress (id) {
    if (this.debug) {
        console.log('pullAddress triggered with', id)
    }
    return this.api().getRequest('/address/'+id, false, (e) => {
        this.state.address = Object.assign({}, e)
    })
}

export async function deleteAddress (id) {
    if (this.debug) {
        console.log('deleteAddress triggered with', id)
    }
    return await this.api().deleteRequest('/address/'+id, () => {
        clearAddress()
    })
}

export async function createAddress (address) {
    if (this.debug) {
        console.log('createAddress triggered with', name)
    }
    let res
    await this.api().postRequest('/address', address, (resc) => {
        res = resc
        this.getAddresses()
    })
    return res.id
}

export async function updateAddress (address = {}) {
    if (this.debug) {
        console.log('updateAddress triggered with', name)
    }
    return await this.api().putRequest('/address/'+this.state.address.id, address, () => {
        return true
    })
}

export function clearAddress() {
    if (this.debug) {
        console.log('clearAddress triggered')
    }
    this.state.address = Object.assign({
        _set: false
    }, address)
}

const addresses = []

export async function getAddresses () {
    if (this.debug) {
        console.log('pullAddresses triggered')
    }
    await this.api().getRequest('/address', false, (e) => {
        this.state.addresses = e;
    });
}

export function clearAddresses() {
    if (this.debug) {
        console.log('clearAddress triggered')
    }
    this.state.addresses = addresses
}

