export async function createOptinSetup(mailid, listid) {
    if (this.debug) {
        console.log('createOptinSetup triggered with', name)
    }
    let res
    await this.api().postRequest('/events/'+ this.state.event.id  +'/optinsetup', {
        confirmationMailingId: mailid,
        listIds: listid
    }, (resc) => {
        res = resc
        this.getEvent(this.state.event.id)
    })
    return res.id
}
